

$(function() {

  // 進場動畫
  var percent=0;
  var timer=setInterval(function(){
    $(".bar").css("width",percent+"%")
    percent+=1
    if(percent>100){
      clearInterval(timer)
      $(".pageloading").addClass("compelet")
    }
  },30);

  $('.scrollLink').bind('click', function(e) {
      e.preventDefault();
      var target = $(this).attr("href");
      $('html, body').stop().animate({ scrollTop: $(target).offset().top}, 1000, function() {
        location.hash = target + 300;
      });
      
      return false;
  });
  
  // top
  $(".float-link .link-btn.top").on('click',function () {
    $("html,body").animate({ "scrollTop": "0" })
  })
  
  $(window).on("load resize scroll", function (e) {
    var scroll = $(this).scrollTop();
    var wdh = $(window).height();
    if (scroll > 50) {
        $(".float-link").addClass("active");
    } else {
        $(".float-link").removeClass("active");
    }
  });

  // 跑馬燈
  $('.dap-tw-item').marquee({
      duration: 15000,
      pauseOnHover: true
  });

  if ($(document).width() < 1080) {
      $('.dap-tw-item').marquee({
          duration: 9000,
          pauseOnHover: true
      });
  };

  // Datepick
  if (/Mobi/.test(navigator.userAgent)) {
    // if mobile device, use native pickers
    $(".date input").attr("type", "date");
    $(".time input").attr("type", "time");
  } else {
    // if desktop device, use DateTimePicker
    $("#datepicker").datetimepicker({
      useCurrent: false,
      format: "L",
      showTodayButton: true,
      icons: {
        next: "fa fa-chevron-right",
        previous: "fa fa-chevron-left",
        today: 'todayText',
      }
    });
    $("#timepicker").datetimepicker({
      format: "LT",
      icons: {
        up: "fa fa-chevron-up",
        down: "fa fa-chevron-down"
      }
    });
  }

});
